



























import { IWalletNftDict } from '../../../store/types'
import { NftFamilyDict } from '../../../store/modules/assets/types'
import BalancePopup from '@/components/misc/BalancePopup/BalancePopup.vue'

import 'reflect-metadata'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { NFTTransferOutput, UTXO } from '@lamina1/lamina1-js/dist/apis/avm'
import { getPayloadFromUTXO } from '@/helpers/helper'
import NftListItem from '@/components/wallet/transfer/NftListItem.vue'
import { IGroupDict, IGroupQuantity } from '@/components/wallet/studio/mint/types'
import { bintools } from '@/AVA'
import AvmNftSelectModal from '@/components/modals/AvmNftSelectModal.vue'

@Component({
    components: {
        AvmNftSelectModal,
        BalancePopup,
        NftListItem,
    },
})
export default class NftList extends Vue {
    addedNfts: UTXO[] = []

    groupUtxos: IGroupDict = {}

    $refs!: {
        popup: BalancePopup
        select_modal: AvmNftSelectModal
    }

    @Prop({ default: false }) disabled!: boolean

    // @Watch('addedNfts')
    // onlistchange(val: UTXO[]) {
    //     this.$emit('change', val)
    // }

    setGroupUtxos(val: IGroupQuantity) {
        this.groupUtxos[val.id] = val.utxos
        this.emit()
    }

    emit() {
        let utxos = []

        for (var id in this.groupUtxos) {
            let gUtxos = this.groupUtxos[id]
            utxos.push(...gUtxos)
        }

        this.$emit('change', utxos)
    }

    // @Watch('groupUtxos')
    // onGroupUtxosChange(val: IGroupDict) {
    //     console.log(this.groupUtxos)
    // }

    get payloads() {
        return this.addedNfts.map((utxo) => {
            return getPayloadFromUTXO(utxo)
        })
    }

    get isEmpty(): boolean {
        return this.nftUTXOs.length === 0
    }

    get nftUTXOs(): UTXO[] {
        return this.$store.state.Assets.nftUTXOs
    }

    get nftDict(): IWalletNftDict {
        // return this.$store.getters.walletNftDict
        return this.$store.getters['Assets/walletNftDict']
    }

    get nftFamsDict(): NftFamilyDict {
        return this.$store.state.Assets.nftFamsDict
    }

    get usedNftIds() {
        return this.addedNfts.map((utxo: UTXO) => {
            return utxo.getUTXOID()
        })
    }

    clear() {
        this.addedNfts = []
        this.groupUtxos = {}
        this.emit()
    }

    addNft(utxo: UTXO) {
        this.addedNfts.push(utxo)
    }

    remove(utxo: UTXO) {
        let famId = bintools.cb58Encode(utxo.getAssetID())
        let groupId = (utxo.getOutput() as NFTTransferOutput).getGroupID()

        // Clear from selected utxos list
        let dictId = `${famId}_${groupId}`
        delete this.groupUtxos[dictId]

        let utxos = this.addedNfts
        for (var i = 0; i < utxos.length; i++) {
            if (utxos[i].getUTXOID() === utxo.getUTXOID()) {
                this.addedNfts.splice(i, 1)
            }
        }

        this.emit()
    }

    showPopup() {
        this.$refs.select_modal.open()
        // this.$refs.popup.isActive = true
    }

    deactivated() {
        this.clear()
    }

    activated() {}
}
