









import { Component, Vue } from 'vue-property-decorator'
import { UrlFormType, UtfFormType } from '@/components/wallet/studio/mint/types'

@Component
export default class Utf8Form extends Vue {
    val = ''

    get isValid(): boolean {
        if (this.val.length === 0 || this.val.length > 1024) {
            return false
        }

        return true
    }

    onInput() {
        let msg: null | UtfFormType = null

        if (this.isValid) {
            msg = {
                text: this.val,
            }
        } else {
            msg = null
        }

        this.$emit('onInput', msg)
    }
}
