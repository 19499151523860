




























import 'reflect-metadata'
import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import { ITransaction } from './types'
import { UTXO } from '@lamina1/lamina1-js/dist/apis/avm'
import { BN } from '@lamina1/lamina1-js'
import { bnToBig, getPayloadFromUTXO } from '@/helpers/helper'
import NftPayloadView from '@/components/misc/NftPayloadView/NftPayloadView.vue'
@Component({
    components: {
        NftPayloadView,
    },
})
export default class TxSummary extends Vue {
    @Prop() orders!: ITransaction[]
    @Prop() nftOrders!: UTXO[]

    cleanNum(val: BN, denom: number) {
        return bnToBig(val, denom).toLocaleString(denom)
    }

    get nftPayloads() {
        return this.nftOrders.map((utxo) => {
            return getPayloadFromUTXO(utxo)
        })
    }

    get isFungibleEmpty() {
        for (var i = 0; i < this.orders.length; i++) {
            let order = this.orders[i]
            if (order.amount.gt(new BN(0))) {
                return false
            }
        }
        return true
    }

    get cleanOrders() {
        const ZERO = new BN(0)
        return this.orders.filter((order) => {
            return order.amount.gt(ZERO)
        })
    }

    get isCollectibleEmpty() {
        return this.nftOrders.length === 0
    }
}
